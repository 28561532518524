exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-about-js": () => import("./../../../src/pages/app-about.js" /* webpackChunkName: "component---src-pages-app-about-js" */),
  "component---src-pages-app-privacy-policy-js": () => import("./../../../src/pages/app-privacy-policy.js" /* webpackChunkName: "component---src-pages-app-privacy-policy-js" */),
  "component---src-pages-app-terms-js": () => import("./../../../src/pages/app-terms.js" /* webpackChunkName: "component---src-pages-app-terms-js" */),
  "component---src-pages-comingsoon-js": () => import("./../../../src/pages/comingsoon.js" /* webpackChunkName: "component---src-pages-comingsoon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-entry-app-js": () => import("./../../../src/pages/entry-app.js" /* webpackChunkName: "component---src-pages-entry-app-js" */),
  "component---src-pages-feature-js": () => import("./../../../src/pages/feature.js" /* webpackChunkName: "component---src-pages-feature-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-predictions-js": () => import("./../../../src/pages/predictions.js" /* webpackChunkName: "component---src-pages-predictions-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-score-viewing-js": () => import("./../../../src/pages/score-viewing.js" /* webpackChunkName: "component---src-pages-score-viewing-js" */),
  "component---src-pages-scoring-platform-js": () => import("./../../../src/pages/scoring-platform.js" /* webpackChunkName: "component---src-pages-scoring-platform-js" */),
  "component---src-pages-scroll-sec-js": () => import("./../../../src/pages/scroll-sec.js" /* webpackChunkName: "component---src-pages-scroll-sec-js" */),
  "component---src-pages-streamer-app-js": () => import("./../../../src/pages/streamer-app.js" /* webpackChunkName: "component---src-pages-streamer-app-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-paginated-js": () => import("./../../../src/templates/paginated.js" /* webpackChunkName: "component---src-templates-paginated-js" */)
}

